import { render, staticRenderFns } from "./purchaseVat.vue?vue&type=template&id=56b3d364&scoped=true&"
import script from "./purchaseVat.js?vue&type=script&lang=js&"
export * from "./purchaseVat.js?vue&type=script&lang=js&"
import style0 from "@/components/pages/common/scss/vat.scss?vue&type=style&index=0&id=56b3d364&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56b3d364",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMain } from 'vuetify/lib/components/VMain';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VMain,VSelect})
