// import ReleaseTable from "@/components/pages/vat/purchase/releaseTable";
import ReleaseTable from "@/components/organisms/FilterList/purchaseReceivable.vue";
import SnackBar from "@/components/organisms/SnackBar/SnackBar";
import LoadingSpinner from "@/components/organisms/LoadingSpinner/LoadingSpinner";
import numFilterDialog from "@/components/organisms/VDialog/numFilterDialog";
import { mapGetters } from "vuex";
import header from "@/assets/table/receivable/receivableHeaders";
import commitName from "@/components/pages/common/ApiActionName.js";

export default {
  data() {
    return {
      header,
      totalArea: ["year", "month", "name"],
      names: commitName["purchaseVat"],

      vatItems: [
        { title: "전체", value: "common" },
        { title: "내수", value: "domestic" },
        { title: "수출", value: "export" },
      ],
    };
  },
  components: {
    ReleaseTable,
    SnackBar,
    LoadingSpinner,
    numFilterDialog,
  },
  computed: {
    ...mapGetters({
      vatList: "getRecivableList",
      receivableTotal: "getRecivableTotal",
      vatType: "getRecivableType",
    }),

    setParam() {
      return {
        multi: JSON.stringify([]),
        field: "",
        sort: 1,
        type: this.vatType,
        kind: this.names.kind,
      };
    },
  },
  methods: {
    async changeVatList() {
      this.$store.dispatch(
        "CALL_SEARCH_API",
        this.initSearchData(this.setParam),
      );
      this.header = this.header.map((x) => {
        x.checked = false;
        return x;
      });
    },

    initSearchData(param) {
      return {
        paramName: this.names.paramName,
        totalApiName: this.names.totalApiName,
        searchApiName: this.names.searchApiName,
        totalData: {
          param,
          commitName: this.names.totalCommitName,
        },
        searchData: {
          param,
          commitName: this.names.searchCommitName,
        },
      };
    },
  },

  async created() {
    this.$store.commit("setAPILoading", false);
  },
  sockets: {
    async purchase(data) {
      if (this.$store.state.auth_company === data.company)
        await this.$store.dispatch("GET_VAT_RECEIVABLE", {
          vatKind: "purchase",
          vatType: this.$store.state.vatSelected,
        });
    },
  },
};
